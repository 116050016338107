<template>
    <div class="about">
        <h1>This is an about page</h1>
        <p>{{loadingProcess}}%</p>
        <button @click="loadImages">load</button>        
        <canvas id="idd11" width="500" height="100"/>
    </div>
</template>

<script>
import { ref } from 'vue'
require('yuki-createjs/lib/easeljs-0.8.2.combined')
export default {
    name:'About',
    setup(){
        // 已加载完成的数量
        const loadCount = ref(0);
        // 已加载的百分比
        const loadingProcess = ref(0);  
        // 执行下载所有图片
        const loadImages = ()=> {            
            let imgs = [
                require('../assets/img/logo.png'),
                require('../assets/img/hll_logo.jpg'),
                require('../assets/img/new.jpg'),
                require('../assets/img/new2.jpg')
            ]
            imgs.forEach((item,index)=>{
                let times = parseInt(index)+1;
                setTimeout(function(){
                    let image = new Image()
                    image.src = item
                    image.onload = () => {
                        console.log("image.onload:length:"+imgs.length);
                        console.log("image.onload:src:"+image.src);
                        loadCount.value++;
                        // 计算图片加载的百分数，绑定到percent变量
                        let percentNum = Math.floor(loadCount.value / imgs.length * 100)
                        loadingProcess.value = percentNum;                    
                    }
                    image.onerror = (e)=>{
                        console.log('加载错误', e);
                    }
                },times*100);
            })     
        }   
        return{
            loadCount,
            loadingProcess,
            loadImages,
        }                
    },
    mounted(){
       this.init();
    },
    methods:{
        init(){
            var canvas = document.getElementById('idd11')
            var stage = new createjs.Stage(canvas)
            var text = new createjs.Text("Hello World!", "36px Arial", "#777")
            text.textAlign = "center"
            text.x = 100
            text.y = 0
            stage.addChild(text)
            stage.update() 
        },        
    }
}
</script>
